import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Coach Hire Services" description="Explore our most popular coach hire services that we offer. Established in 1949, Tetley's Coaches is one of Yorkshire's oldest coach companies and has provided safe and reliable coach transport in Yorkshire for over 70 years." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent headblock page-article">
            <h1 className="headblock__heading">Our services</h1>
            <p className="headblock__byline">Find out more about the services we operate and offer at Tetley's Coaches</p>
          </div>
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div className="news-article-body">
              <h2 style={{ marginBottom: '1em' }}>Contracted & shuttle services</h2>
              <div id="services" className="block-services__list-parent">
                <ul className="block-services__list">
                  <li><Link to="/staff-shuttle-capita-arlington-business-centre/">Capita, Arlington Business Centre</Link></li>
                  <li><Link to="/staff-shuttle-forza-foods/">Forza Foods Ltd</Link></li>
                  <li><Link to="/school-services/">Home-to-school services</Link></li>
                </ul>
              </div>
              <div className="block-services__list-parent">
                <ul className="block-services__list">
                  <li><a href="https://www.wymetro.com/wakefieldfcb">Wakefield Free City Bus</a></li>
                  <li><a href="https://www.emmerdalevillagetour.co.uk/">Emmerdale Village Tour</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div className="news-article-body">
              <h2 style={{ marginBottom: '1em' }}>Private hire</h2>
              <div id="services" className="block-services__list-parent">
                <ul className="block-services__list">
                  <li><Link to="/coach-hire-services/airport-transfers/" title="Coach Hire For Airport Transfers">Airport Transfers</Link></li>
                  <li><Link to="/coach-hire-services/club-pub-trips/" title="Coach Hire For Club and Pub Trips">Club and Pub Trips</Link></li>
                  <li><Link to="/coach-hire-services/coast-country-day-trips/" title="Coach Hire For Coast and Country Day Trips">Coast and Country Day Trips</Link></li>
                  <li><Link to="/coach-hire-services/college-university-trips/" title="Coach Hire For College and University Trips">College and University Trips</Link></li>
                  <li><Link to="/coach-hire-services/community-groups/" title="Coach Hire For Community Groups">Community Groups</Link></li>
                  <li><Link to="/coach-hire-services/contract-coach-hire/" title="Coach Hire For Contract Work">Contract Work</Link></li>
                  <li><Link to="/coach-hire-services/corporate-events-coach-hire/" title="Coach Hire For Corporate and Events">Corporate and Events</Link></li>
                  <li><Link to="/coach-hire-services/day-at-the-races/" title="Coach Hire For A Day At The Races">Day At The Races</Link></li>
                  <li><Link to="/coach-hire-services/wheelchair-disabled-access-coach-hire/" title="Wheelchair friendly and disabled access coach hire">Disabled access coach hire</Link></li>
                </ul>
              </div>
              <div className="block-services__list-parent">
                <ul className="block-services__list">
                  <li><Link to="/coach-hire-services/funerals/" title="Coach Hire For Funerals">Funerals</Link></li>
                  <li><Link to="/coach-hire-services/group-tours/" title="Coach Hire For Group Tours">Group Tours</Link></li>
                  <li><Link to="/coach-hire-services/weddings-engagements-parties-nights-out/" title="Coach Hire For Weddings, Engagements, Parties and Nights Out">Parties and Nights Out</Link></li>
                  <li><Link to="/coach-hire-services/school-trips/" title="Coach Hire For School Trips">School Trips</Link></li>
                  <li><Link to="/coach-hire-services/sporting-events/" title="Coach Hire For Sporting Events">Sporting Events</Link></li>
                  <li><Link to="/coach-hire-services/theatre-concerts-cultural-events/" title="Coach Hire For Theatre, Concerts &amp; Cultural Events">Theatre, Concerts &amp; Cultural Events</Link></li>
                  <li><Link to="/coach-hire-services/theme-parks/" title="Coach Hire For Theme Parks">Theme Parks</Link></li>
                  <li><Link to="/coach-hire-services/weddings-engagements-parties-nights-out/" title="Coach Hire For Weddings, Engagements, Parties and Nights Out">Weddings and Engagements</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
